<template>
  <div class="no-pad-wrap mb-4">
    <!-- <MetricsGrid
      :requested-metrics="'last100Journal'"
      :grid-type="'compact'"
    /> -->
    <tabs
      class="bb-none"
    >
      <div
        v-for="tab in tabPage.tabs"
        :key="tab.index"
        class="tab-v-for"
      >
        <tab
          :index="tab.index"
        >
          <div class="tab-article">
            <!-- <h5> Broadcasts </h5>
            <div class="home-broadcasts">
              <div class="broadcast-right-margin">
                <p> {{ circle.info }} </p>
                <BroadcastGrid
                  :grid-type="'single-row'"
                  :broadcasts="broadcasts"
                />
              </div>
            </div> -->
            <h5> Articles </h5>
            <article-cards
              :articles="articles"
              :tab="tab.name"
            />
            <!-- <iframe
              src="https://erzjg66fg6pfihr.sg.qlikcloud.com/single/?appid=3088705d-3e6c-46f8-92ec-38ca79840f98&sheet=087d3ab6-96b7-4820-bd71-c68f15d89226&theme=horizon&opt=ctxmenu,currsel"
              style="border:none;width:100%;height:100%;"
            /> -->
          </div>
        </tab>
      </div>
      <template v-slot:after>
        <div class="greeting">
          <p class="m-0">
            Welcome 🎉🎉😎
          </p>
        </div>
      </template>
    </tabs>
  </div>
</template>

<script>
import useTabs from '@/composables/useTabs'
import useUserSingle from '@/composables/users/useUserSingle'
import useArticles from '@/composables/articles/useArticles'
import useCategories from '@/composables/useCategories'
import useCircle from '@/composables/useCircle'
// import BroadcastGrid from '@/views/components/broadcast/BroadcastGrid.vue'
import tab from '../components/appTab.vue'
import tabs from '../components/appTabs.vue'
import ArticleCards from '../components/blog/ArticleCards.vue'

// import MetricsGrid from '../components/MetricsGrid.vue'

export default {
  components: {
    tab,
    tabs,
    ArticleCards,
    // BroadcastGrid,
    // MetricsGrid,
  },
  setup() {
    const { tabPage } = useTabs()
    const { user } = useUserSingle()
    const {
      setCategoryActive,
      categoriesList,
      categoryActive,
    } = useCategories()
    const {
      getArticlesAll,
      getArticleDrafts,
      articleDrafts,
      articles,
    } = useArticles()
    getArticlesAll()

    const { getPublicPost, broadcasts } = useCircle()
    getPublicPost({ type: 'broadcasts' })

    // const { getJournalMetrics, journalMetrics } = useJournal()

    // getJournalMetrics({ authId: userAuthId })
    return {
      tabPage,
      //
      broadcasts,
      // marketOpensIn,
      // journalMetrics,
      user,
      articles,
      categoriesList,
      categoryActive,
      setCategoryActive,
      getArticleDrafts,
      articleDrafts,
    }
  },
  data() {
    return {
      circle: {},
    }
  },
  methods: {
    async testCircleNode() {
      const options = {
        method: 'POST',
        url: `${process.env.VUE_APP_MLFX_API_BASE}/circle/coming-soon`,
      }
      this.circle = await this.$axios.request(options)
    },
  },
}
</script>

<style lang="scss">

.tez {
  width: 100%;
  height: 100vh;
}
.b-link {
  width: auto;
}
.card-text {
  margin-top: 1.5rem;
}
.tab-pane {
  height: 100%;
}

.tezer {
  overflow: hidden;
  ::-webkit-scrollbar {
    display: none !important;
  }
}

.home-broadcasts {
  background-color: lightblue;
  padding: 2rem 0 2rem 2rem;
  margin-bottom: 2rem;
  border-radius: 8px;
  overflow: scroll;
  &::-webkit-scrollbar:horizontal {
    display: block !important;
    width: 16px;
    height: 16px;
  }
  &::-webkit-scrollbar-thumb {
    margin-top: 1rem;
    background-color: #5a5a5a;
    border-radius: 3rem;
    border: 4px solid transparent;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-track {
    background-color: hsla(0, 0%, 93%, 0.356);
    // border-left: 1px solid rgb(34, 34, 34);
  }
  .broadcast-right-margin {
    display: inline-block;
    margin-right: 2rem;
  }
}

.myCard {
  position: relative;
  border-radius: 6px;
  margin-bottom: 2rem;
  box-shadow: 6px 6px 10px 2px hsla(0, 0%, 0%, 0.08);
  overflow: hidden;
}

.metric-botbar {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  column-gap: 2rem;
  row-gap: 2rem;
  margin-bottom: 2rem;
  align-items: center;
  .journal-metric {
    border: 1px solid #e4e4e4;
    box-shadow: 0 4px 24px 0 rgb(43 43 43 / 10%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 50px;
    background-color: white;
    border-radius: 6px;
    padding: 0.6rem;
  }
}

.dark-layout {
  .pinned-item {
    border: 1px solid rgb(83, 83, 83);
    .pin-content {
      color: rgba(255, 255, 255, 0.972);
      background-color: hsla(0, 0%, 6%, 0.7);
    }
  }
}
</style>
