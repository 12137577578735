<template>
  <div
    v-if="articles"
    class="article-cards"
  >
    <Card
      v-for="article in articles"
      :key="article.id"
    >
      <div>
        <!-- <img :src="imgWithTransforms({ path: article.featuredImage, transforms: 'c_scale,w_640' })"> -->
        <h3 class="card-title">
          {{ article.title }}
        </h3>
        <div
          v-if="article.featuredImage"
          class="card-image"
          :style="{
            backgroundImage:
              'url(' +
              imgWithTransforms({
                path: article.featuredImage,
                type: ['article', 'card'],
              }) +
              ')',
            backgroundSize: 'contain',
          }"
        >
          <span
            v-if="showCategory.includes(tab)"
            class="card-tag primary absolute"
          >
            {{ article.category.name }}</span>
        </div>
        <div class="card-info">
          <div class="card-tag-wrap">
            <span
              v-for="articleTag in article.tags"
              :key="articleTag.tag.id"
              class="card-tag"
            >{{ articleTag.tag.name }}</span>
          </div>
          <span class="ml-1">
            {{ new Date(article.createdAt).toDateString() }}
          </span>
        </div>
        <p class="card-excerpt">
          {{ article.excerpt }}
        </p>
      </div>
      <router-link
        v-if="article.status.name !== 'Draft'"
        :to="{
          name: 'article',
          params: {
            id: String(article.id),
            title: article.title.replaceAll(' ', '-').toLowerCase(),
          },
        }"
        append
      >
        <div class="d-flex justify-content-end">
          <b-button
            class="dir-card-button"
            variant="outline-primary"
            @click="setArticleActive({ articleId: article.id })"
          >More</b-button>
        </div>
      </router-link>
    </Card>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Card from '@/views/components/appCard.vue'
import useArticles from '@/composables/articles/useArticles'
import useCloudinary from '@/composables/useCloudinary'
import useUserSingle from '@/composables/users/useUserSingle'

export default {
  name: 'ArticleCards',
  components: {
    Card,
    BButton,
  },
  props: {
    articles: {
      type: Array,
      default: () => [],
    },
    tab: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { user } = useUserSingle()
    const { setArticleActive } = useArticles()
    const { imgWithTransforms } = useCloudinary()

    return {
      imgWithTransforms,
      setArticleActive,
      user,
    }
  },
  data() {
    return {
      showCategory: ['New', 'Drafts'],
      imgURL: null,
    }
  },
}
</script>

<style lang="scss">
</style>
