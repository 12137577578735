var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.articles)?_c('div',{staticClass:"article-cards"},_vm._l((_vm.articles),function(article){return _c('Card',{key:article.id},[_c('div',[_c('h3',{staticClass:"card-title"},[_vm._v(" "+_vm._s(article.title)+" ")]),(article.featuredImage)?_c('div',{staticClass:"card-image",style:({
          backgroundImage:
            'url(' +
            _vm.imgWithTransforms({
              path: article.featuredImage,
              type: ['article', 'card'],
            }) +
            ')',
          backgroundSize: 'contain',
        })},[(_vm.showCategory.includes(_vm.tab))?_c('span',{staticClass:"card-tag primary absolute"},[_vm._v(" "+_vm._s(article.category.name))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"card-info"},[_c('div',{staticClass:"card-tag-wrap"},_vm._l((article.tags),function(articleTag){return _c('span',{key:articleTag.tag.id,staticClass:"card-tag"},[_vm._v(_vm._s(articleTag.tag.name))])}),0),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(new Date(article.createdAt).toDateString())+" ")])]),_c('p',{staticClass:"card-excerpt"},[_vm._v(" "+_vm._s(article.excerpt)+" ")])]),(article.status.name !== 'Draft')?_c('router-link',{attrs:{"to":{
        name: 'article',
        params: {
          id: String(article.id),
          title: article.title.replaceAll(' ', '-').toLowerCase(),
        },
      },"append":""}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"dir-card-button",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.setArticleActive({ articleId: article.id })}}},[_vm._v("More")])],1)]):_vm._e()],1)}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }