import {
  ref,
  computed,
  // readonly,
} from '@vue/composition-api'
import axios from 'axios'

const circle = ref({
  broadcasts: [],
})

// const myHeaders = new Headers()
// myHeaders.append("Authorization", "Token {{api_token}}")

// const token = localStorage.getItem('authToken')

export default function useCircle() {
  // const ciicleAPI = process.env.VUE_APP_CIRCLE_API

  function processPost({ posts, type }) {
    posts.data.forEach(a => {
      let singlePost = {
        show: false,
        ...a,
      }
      if (!circle.value[type]?.some(b => b.id === singlePost.id)) circle.value[type].push(singlePost)
      singlePost = null
    })
  }

  async function getPublicPost({ type }) {
    const spaceIds = {
      broadcasts: 391772,
      'coming-soon': 398254,
    }
    const options = {
      method: 'POST',
      url: `${process.env.VUE_APP_MLFX_API_BASE}/circle/posts`,
      data: {
        spaceId: spaceIds[type],
      },
    }
    const posts = await axios.request(options)
    processPost({ posts, type })
  }

  return {
    getPublicPost,
    broadcasts: computed(() => circle.value.broadcasts),
  }
}
